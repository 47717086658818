import React, { useEffect, useState, useRef } from 'react'
import Logo from "../assets/images/logo.png";
import Down_Arrow from "../assets/images/down arrow.svg";
import { Link } from 'react-router-dom';
import Http from "../Http_Api";

export default function Navbar() {
    const [scroll, setScroll] = useState(0);
    const [scrollCount, setScrollCount] = useState(0);
    const [headerTOP, setHeaderTOP] = useState([])
    const [mainLogo, setMainLogo] = useState([])
    const [headerMID, setHeaderMID] = useState([])
    const [socialLink, setSocailLink] = useState([])
    const Myscroll = useRef(0)
    useEffect(() => {
        header_top();
        header_middle_logo();
        header_middle();
        header_middle_socials();

        window.addEventListener('scroll', while_user_scroll);
    }, [])

    const header_top = async () => {
        await Http.get('/article/header_top').then((res, req) => {
            setHeaderTOP(res.data);
        })
    }
    const header_middle_logo = async () => {
        await Http.get('/article/header_middle/logo').then((res, req) => {
            setMainLogo(res.data);
        })
    }
    const header_middle = async () => {
        await Http.get('/article/header_middle').then((res, req) => {
            setHeaderMID(res.data);
        })
    }
    const header_middle_socials = async () => {
        await Http.get('/article/header_middle/socials').then((res, req) => {
            setSocailLink(res.data);
        })
    }

    // console.log(Object.keys(headerMID).map((e) => (
    //     console.log(headerMID[e].title)
    // )))


    const while_user_scroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        Myscroll.current = winScroll;
        header_top_visiblity();
        setScroll(winScroll)
    }

    const header_top_visiblity = () => {
        let header_top = document.getElementsByClassName('header-top')[0];
        let header = document.getElementsByTagName('header')[0];
        if (Myscroll.current > 150) {
            header.classList.add('sticky');
        }
        if (Myscroll.current < 150) {
            header.classList.remove('sticky');
        }
    }
    return (
        <>
            <header>
                <div className="header-top " >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="top-notice">
                                    <ul>
                                        {headerTOP.map((headerTOP, index) => (
                                            <li key={headerTOP.content_id}><i className={headerTOP.single_link}></i>{headerTOP.title}</li>
                                        ))}
                                        <li className="mulitlang"><a href="#">English </a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-center-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-center">
                                    <div className="logo mx-auto">
                                        {Object.keys(mainLogo).map((key) => (

                                            <Link to="/" key={mainLogo[key].content_id} >
                                                <img src={mainLogo[key].single_link}
                                                    alt="" />
                                            </Link>

                                        ))}


                                    </div>
                                    <div className="header-center-contacts header_hide_in_Mobile">
                                        {Object.keys(headerMID).map((key) => (
                                            <div key={headerMID[key].content_id} className={"single-header-contact " + headerMID[key].css_class}>
                                                <i className={headerMID[key].single_link}></i>
                                                <div className="header-contents">
                                                    <p><a className='' href={headerMID[key].multiple_link} target='_blank' >{headerMID[key].title} </a></p>
                                                    <p>{headerMID[key].short_description} </p>
                                                </div>
                                            </div>
                                        ))}
                                        {/* <div className="single-header-contact">
                                            <i className="fa fa-phone-alt"></i>
                                            <div className="header-contents">
                                                <p><a className='' href="tel: +8801933794616">(+৮৮) ০১৯৩৩-৭৯৪৬১৬  </a></p>
                                                <p>আমাদের সাথে যোগাযোগ</p>
                                            </div>
                                        </div>
                                        <div className="single-header-contact header_hide_in_Mobile">
                                            <i className="fa fa-map-marker-alt"></i>
                                            <div className="header-contents">
                                                <a href="https://maps.app.goo.gl/2PUxQ9AbWH2JKsWx5" target='_blank' >
                                                    <p>রশিদপুর, জামালপুর সদর</p>
                                                    <p>জামালপুর - ২০০০</p>
                                                </a>
                                            </div>
                                        </div> */}
                                        <div className="socials">
                                            <ul>
                                                {Object.keys(socialLink).map((key) => (
                                                    <li key={socialLink[key].content_id} className='mx-1'><a href={socialLink[key].multiple_link}><i className={socialLink[key].single_link}></i></a></li>
                                                ))}
                                                {/* <li className='mx-1'><a href="#"><i className={"fab fa-twitter"}></i></a></li>
                                                <li className='mx-1'><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                <li className='mx-1'><a href="#"><i className="fab fa-instagram"></i></a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-main-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-main">
                                    <div className="main-menu-wrapper">
                                        <div className="nav-header">
                                            <div className="hamburger"></div>
                                        </div>
                                        <div className="collapse-nav-inner">
                                            <div className="menu-trigger"></div>
                                            <div className="collapse-nav ">
                                                <div className="nav-header">
                                                    <div className="hamburger"></div>
                                                </div>
                                                <div className="main-menu  ">
                                                    <ul>
                                                        <li className="active_link"><Link to={'/'}>হোম</Link></li>
                                                        <li className="menu"><a href="#">আমাদের সম্পর্কে
                                                            <i className=" fa-solid fa-caret-down" style={{ marginLeft: "0.5rem" }}></i></a>
                                                            <div className='anti_menu'>
                                                                <ul className="">
                                                                    <li><Link to={"/history"}>ইতিহাস</Link></li>

                                                                    <li><Link to={"/target"}>লক্ষ্য</Link></li>
                                                                    <li><Link to={"/employee"}>Employe</Link></li>

                                                                    <li className="sub_menu"><a href="#">বানি
                                                                        <i className=" fa-solid fa-caret-down" style={{ marginLeft: "0.5rem" }}></i></a>
                                                                        <div className="sub_anti_menu">
                                                                            <ul className="">
                                                                                <li><Link to={"/Chief_Director"}>প্রধান পরিচালকের</Link></li>

                                                                                <li><Link to={"/Executive_Director"}>নির্বাহী পরিচালকের</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className="menu"><a href="#">ঘোষণা
                                                            <i className=" fa-solid fa-caret-down" style={{ marginLeft: "0.5rem" }}></i></a>
                                                            <div className="anti_menu">
                                                                <ul className="">
                                                                    <li><Link to={"/NoticeBorde"}>নোটিশ</Link></li>

                                                                    <li><Link to={"/EventAll"}>ইভেন্ট</Link></li>

                                                                    <li><Link to={"/NewsAll"}>নিউজ</Link></li>
                                                                    {/* <li><Link to={"/aca_Cal"}>Academic Calander</Link></li> */}
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li className=""><a href="/gallary">গ্যালারি</a></li>
                                                        <li className=""><Link to="/contactUs">যোগাযোগ</Link></li>
                                                        {/* <li className=""><Link to="/contactUs">অনুদান  </Link></li> */}
                                                    </ul>
                                                    {/* <div className="extra-menu "> */}
                                                    {/* <div className="donation mx-1">
                                                        <Link to="/">Login</Link>
                                                    </div> */}

                                                    <div className="donation mx-4 mt-5 hide_in_pc same_height_width" >
                                                        <Link to="/donation">অনুদান</Link>
                                                    </div>
                                                    <div className=" My_menu_search_PC_hide same_height_width mx-4 ">
                                                        <input type="text"
                                                            placeholder="অনুসন্ধান" />
                                                        <i className="fas fa-search" ></i>
                                                    </div>
                                                </div>
                                                <div className='contact_menu_mob hide_in_pc text-white mx-3 mb-3'>
                                                    <ul className='d-flex flex-column '>
                                                        <li className='d-flex  '>
                                                            <div className="single-header-contact" style={{ width: "100%" }}>
                                                                <i className="fa fa-phone-alt"></i>
                                                                <div className="header-contents">
                                                                    <p><a href="tel: +8801933794616">(+৮৮) ০১৯৩৩-৭৯৪৬১৬</a></p>
                                                                    <p>আমাদের সাথে যোগাযোগ</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className='d-flex  my-4'>
                                                            <div className="single-header-contact" style={{ width: "100%" }}>
                                                                <i className="fa fa-map-marker-alt" style={{ width: "3rem" }}></i>
                                                                <div className="header-contents">
                                                                    <p className='text-white'><a href="https://maps.app.goo.gl/2PUxQ9AbWH2JKsWx5" >রশিদপুর, জামালপুর সদর, জামালপুর - ২০০০</a></p>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="extra-menu ">
                                                <div className="menu-search hide_in_Mobile mx-3">
                                                    <input type="text"
                                                        placeholder="অনুসন্ধান" />
                                                    <i className="fas fa-search" ></i>
                                                </div>
                                                <div className="donation hide_in_Mobile">
                                                    <Link to="/donation">অনুদান</Link>
                                                </div>
                                                <div className="donation mx-3" style={{}}>
                                                    <Link to="/login">Login</Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
            <spacer></spacer>
        </>
    )
}
