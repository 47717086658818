import React, { useEffect, useRef, useState } from 'react'
import Contact from '../assets/images/contact.jpg';
import News_1 from "../assets/images/news1.png"
import { Link } from 'react-router-dom';
import Http from '../Http_Api';
export default function NewsAll() {
    const [dataNews_title, setDataNews_title] = useState([]);
    const [dataNews_inner, setDataNews_inner] = useState([]);
    const windoSCrollCount = useRef(0)
    
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

        data_news_title();
        data_news_inner();
    }, [])
    const data_news_title = async () => {
        await Http.get('/article/news_page/title').then((res, req) => {
            let value = []
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            setDataNews_title(value)
        })
    }
    const data_news_inner = async () => {
        await Http.get('/news').then((res, req) => {
            let value = []
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            setDataNews_inner(value)
        })
    }
    // console.log(dataNews_inner)
    // if (dataNews_inner.length >0) {
    //     console.log(dataNews_inner)
    // }
    // else{
    //     data_news_inner()
    // }
    return (

        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(${Contact})` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">

                                <div className="banner-contents">
                                    <h1 className='d-block'>{dataNews_title.length > 0 ? dataNews_title[0].title : ''}</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                        <Link to={'/'} className="breadcrumbs__item is-active">{dataNews_title.length > 0 ? dataNews_title[0].title : ''}</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="newsAll" style={{ margin: "5% 0" }} >
                <div className="container">
                    <div className="section-title">
                        <h2>{dataNews_title.length > 0 ? dataNews_title[0].title : ''}</h2>
                        <p>{dataNews_title.length > 0 ? dataNews_title[0].short_description : ''}</p>
                    </div>
                    <div className="row ">
                        {dataNews_inner.length > 0 ? dataNews_inner.map((data, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                                <div className="single-news">
                                    <div className="news-image"
                                        style={{ backgroundImage: `url(${data.img_url})` }}></div>

                                    <div className="news-info row  bg-success text-white py-4 px-5">
                                        {/* <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>{data.user_id}</span></span>
                                        <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: {data.starting_day}</span><span className='to-date'>To: {data.ending_day}</span></span>
                                        <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >{data.location}</span></span> */}
                                        <div className='col-1'>
                                            <div><i class="fa-solid fa-circle-user"></i></div>
                                            <div><i class="fa-solid fa-calendar-days"></i></div>
                                            <div><i class="fa-solid fa-location-dot"></i></div>
                                        </div>
                                        <div className='col-10'>
                                            <div>{data.user_id}</div>
                                            <div>{data.location}</div>
                                            <div>
                                                <span>From <span>{data.starting_day}</span></span>
                                                <span>TO <span>{data.ending_day}</span></span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="news-details">
                                        <h4>{data.title}</h4>
                                        <p>{data.description.slice(0, 180)}... </p>
                                        <Link to={`/News/${data.id}`}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        )) : data_news_inner()}
                        {/* <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/News/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/News/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/News/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/News/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/News/id"}>Read More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-10 col-xs-10 col-12 mx-auto mb-5 ">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>

                                <div className="news-info  d-flex flex-column bg-success text-white py-4 px-5">
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-circle-user"></i></span><span>Data Entry Operator</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-calendar-days"></i></span><span className='from-date ' style={{ marginRight: '0.8rem' }}>From: 18-06-2023</span><span className='to-date'>To: 20-06-2023</span></span>
                                    <span><span style={{ marginRight: '0.8rem' }}><i class="fa-solid fa-location-dot"></i></span><span >Bangladesh</span></span>
                                </div>
                                <div className="news-details">
                                    <h4>Did you know that</h4>
                                    <p>Due to recent legislative changes and increased compliance with international tax
                                        avoidance regulations</p>
                                    <Link to={"/News/id"}>Read More</Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section >
        </>
    )
}
