import logo from './logo.svg';
import './App.css';
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Home from "./main_pages/Home.js";
import { Outlet } from 'react-router-dom';
function App() {
  return (
    <>
      <Navbar></Navbar>
      <Outlet />
      <Footer></Footer>
    </>
  );
}

export default App;
