import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Http from "../Http_Api";
export default function Notice() {
    const [dataNotice_inner, setDataNotice_inner] = useState([]);
    const windoSCrollCount = useRef(0)
    
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

        get_data_notice_inner();
    }, [])
    const get_data_notice_inner = async () => {
        await Http.get('/noticeAll').then((res, req) => {
            let value = [];
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            if (value.length > 0) {
                setDataNotice_inner(value);
                console.log(value);
            }
        })
    }
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?news)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>Notice Board</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                        <Link to={'/'} className="breadcrumbs__item is-active">Full Profile</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='Notice'>
                <div className="container" style={{ marginTop: '5rem' }}>
                    {/* <div className="row " > */}
                    {/* <!-- Add Notice Area Start Here --> */}
                    {/* <div className="col-4-xxxl col-12">
                            <div className="card height-auto">
                                <div className="card-body">
                                    <div className="heading-layout1">
                                        <div className="item-title">
                                            <h3>Create A Notice</h3>
                                        </div>
                                        <div className="dropdown">
                                            <a className="dropdown-toggle" href="#" role="button"
                                                data-toggle="dropdown" aria-expanded="false">...</a>

                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red"></i>Close</a>
                                                <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green"></i>Edit</a>
                                                <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel"></i>Refresh</a>
                                            </div>
                                        </div>
                                    </div>
                                    <form className="new-added-form">
                                        <div className="row">
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Title</label>
                                                <input type="text" placeholder="" className="form-control" />
                                            </div>
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Details</label>
                                                <input type="text" placeholder="" className="form-control" />
                                            </div>
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Posted By </label>
                                                <input type="text" placeholder="" className="form-control" />
                                                <i className="fas fa-user"></i>
                                            </div>
                                            <div className="col-12-xxxl col-lg-6 col-12 form-group">
                                                <label>Date</label>
                                                <input type="text" placeholder="" className="form-control air-datepicker" />
                                                <i className="far fa-calendar-alt"></i>
                                            </div>
                                            <div className="col-12 form-group mg-t-8">
                                                <button type="submit" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">Save</button>
                                                <button type="reset" className="btn-fill-lg bg-blue-dark btn-hover-yellow">Reset</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    {/* <!-- Add Notice Area End Here --> */}
                    {/* <!-- All Notice Area Start Here --> */}
                    {/* <div className="col-8-xxxl col-12"> */}
                    <div className="card height-auto" style={{ border: "0" }}>
                        <div className="card-body">
                            <div className="heading-layout1">
                                <div className="item-title">
                                    <h3>Notice Board</h3>
                                </div>
                                <div className="dropdown">
                                    <a className="dropdown-toggle" href="#" role="button"
                                        data-toggle="dropdown" aria-expanded="false">...</a>

                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#"><i className="fas fa-times text-orange-red"></i>Close</a>
                                        <a className="dropdown-item" href="#"><i className="fas fa-cogs text-dark-pastel-green"></i>Edit</a>
                                        <a className="dropdown-item" href="#"><i className="fas fa-redo-alt text-orange-peel"></i>Refresh</a>
                                    </div>
                                </div>
                            </div>
                            <form className="mg-b-20">
                                <div className="row gutters-8">
                                    <div className="col-lg-5 col-12 form-group">
                                        <input type="text" placeholder="Search by Date ..." className="form-control" />
                                    </div>
                                    <div className="col-lg-5 col-12 form-group">
                                        <input type="text" placeholder="Search by Title ..." className="form-control" />
                                    </div>
                                    <div className="col-lg-2 col-12 form-group">
                                        <button type="submit" className="fw-btn-fill btn-gradient-yellow">SEARCH</button>
                                    </div>
                                </div>
                            </form>
                            <div className="notice-board-wrap">
                                {dataNotice_inner.map((data) => (
                                    <div className="notice-list">
                                        <div className={"post-date " + (data.time_css)}>{data.time}</div>
                                        <h6 className="notice-title"><a href="#">{data.description}</a></h6>
                                        <div className="entry-meta"> {data.user_id} / <span>5 min ago</span></div>
                                    </div>
                                ))}
                                {/* <div className="notice-list">
                                    <div className="post-date bg-yellow">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-pink">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-skyblue">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-yellow">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-pink">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-skyblue">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-yellow">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div>
                                <div className="notice-list">
                                    <div className="post-date bg-pink">16 June, 2019</div>
                                    <h6 className="notice-title"><a href="#">Great School Great School manag mene esom
                                        text of the printing Great School manag mene esom  text of the printing manag
                                        mene esom  text of the printing.</a></h6>
                                    <div className="entry-meta">  Jennyfar Lopez / <span>5 min ago</span></div>
                                </div> */}
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                        {/* <!-- All Notice Area End Here --> */}
                    </div>
                </div>
            </section>

        </>
    )
}
