import React, { useEffect, useRef, useState } from 'react'
import Contact from '../assets/images/contact.jpg';
import News_1 from "../assets/images/news1.png"
import { Link, useLocation, useParams } from 'react-router-dom';
import Http_Api from '../Http_Api';
export default function News_Event_view() {
    const location = useLocation()
    const { hash, pathname, search } = location;
    let array_path = pathname.split(/[/]+/)
    let main_title = '';
    let url = '';
    if (array_path.includes('News')) {
        main_title = array_path[array_path.indexOf('News')]
        url = 'NewsAll'
    }
    if (array_path.includes('Event')) {
        main_title = array_path[array_path.indexOf('Event')]
        url = 'EventAll'
    }

    let { id } = useParams()
    // const [data_table_title, setData_table_title] = useState();
    const [data_table_inner, setData_table_inner] = useState([]);
    const windoSCrollCount = useRef(0)
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        get_data_table_by_id();
    }, [])

    const get_data_table_by_id = () => {
        if (array_path.includes('News')) {

            Http_Api.get(`/news/${id}`).then((res, req) => {
                // let value = []
                // Object.keys(res.data).map((key) => {
                //     value.push(res.data[key]);
                // })
                // if (value.length > 0) {
                    setData_table_inner(res.data)
                // }
            })
        }
        if (array_path.includes('Event')) {
            Http_Api.get(`/news/${id}`).then((res, req) => {
                // let value = []
                // Object.keys(res.data).map((key) => {
                //     value.push(res.data[key]);
                // })
                // if (value.length > 0) {
                setData_table_inner(res.data)
                // }
            })
        }
    }

    return (
        <>
            {/* dont change the id and custom class   */}
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(${Contact})` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>{main_title}</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                        <Link to={'/'} className="breadcrumbs__item is-active">{main_title}</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='News_Event_View' style={{ margin: "5% 0" }}>
                <div className="container">
                    <div className='mx-5'>
                        <Link to={'/'+url}>
                            <div className='py-2 px-3 rounded-pill bg-success text-white d-flex justify-content-between  ' style={{ width: '10rem', background: "linear-gradient(90deg, rgba(21,108,66,1) 38%, rgb(115 190 154) 92%)" }}>
                                <span>Go back</span>
                                <span><i class="fa-solid fa-angles-left"></i></span>
                            </div>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className='bg-white p-5 '>
                                <h3 className='font-weight-bold'>{data_table_inner.title}</h3>
                                <p>
                                    <span style={{ marginRight: "2rem" }}>
                                        <span style={{ marginRight: "0.5rem" }}>
                                            <i class="fa-solid fa-circle-user"></i>
                                        </span>
                                        <span>
                                            {data_table_inner.user_id}
                                        </span>
                                    </span>
                                    <span style={{ marginRight: "1rem" }}>
                                        <span style={{ marginRight: "0.5rem" }}>
                                            <i class="fa-solid fa-calendar-days"></i>
                                        </span>
                                        <span>
                                            {data_table_inner.starting_day}
                                        </span>
                                    </span>


                                </p>
                                <img className='rounded' style={{ width: "100%" }} src={data_table_inner.img_url} alt="" />
                                <p className='mt-4'>{data_table_inner.description}</p>
                            </div>
                        </div>
                        {/* dont change the id and custom class   */}
                        <div id='history' className="col-lg-5 " >
                            <div className='bg-white pt-5'>
                                <h5 className='font-weight-bold'><span style={{ marginRight: "0.8rem" }}><i class="fa-solid fa-box-archive"></i></span> সর্বশেষ সংবাদ</h5>
                                <hr className='h-1' style={{ borderTop: "2px solid #156C42", marginTop: "0", marginBottom: '0.8rem' }} />
                                <div className='history '>
                                    <div className='d-flex'>
                                        <img style={{ borderRadius: "0.5rem" }} src={'https://source.unsplash.com/random/120x40/?islamic'} alt="" />
                                        <p className='d-flex flex-column h6' style={{ marginLeft: "0.8rem" }}><Link to={'#'}>বিনা মূল্যে ২০২৩ সালের রমজানের ক্যালেন্ডার বিতরন করা হইছে</Link>
                                            <span className='d-flex justify-content-between h6 mt-3'>
                                                <span><span className='mx-2'><i class="fa-solid fa-circle-user"></i></span><span>User</span></span>
                                                <span><span className='mx-2'><i class="fa-solid fa-calendar-days"></i></span> <span>30-06-2023</span></span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
