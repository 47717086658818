import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import LightGallery from 'lightgallery/react';
import Gallary_1 from "../assets/images/gallary_1.jpeg"
import Gallary_2 from "../assets/images/gallary_2.jpeg"
import Gallary_3 from "../assets/images/gallary_3.jpeg"
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


export default function Gallary() {
  const windoSCrollCount = useRef(0)
  useEffect(() => {
    if (windoSCrollCount.current === 0) {
      console.log('asdfasfsa')
      window.scrollTo({ top: 0, behavior: "smooth" })
      windoSCrollCount.current = 1
    }
    let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
  }, [])
  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  return (
    <>

      <section id="page-banner">
        <div className="banner-inner"

          style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?news)` }}>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner-contents">
                  <h1 className='d-block'>Gallary</h1>
                  <nav className="breadcrumbs " style={{ opacity: "1" }}>
                    <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                    <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                    <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                    <Link to={'/'} className="breadcrumbs__item is-active">Gallary</Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="gallary" className='mx-auto'>
        <div className="container my-5">
          <h4 className='text-center'>We work for your satisfaction</h4>
          <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
          >

            <a href="/static/media/gallary_1.1820eb133274d8c3faf2.jpeg">
              <img style={{ margin: "1rem", width: "21.1rem" }} alt="img1" src={Gallary_1} />
            </a>
            <a href="/static/media/gallary_1.1820eb133274d8c3faf2.jpeg">
              <img style={{ margin: "1rem", width: "21.1rem" }} alt="img1" src={Gallary_1} />
            </a>
            <a href="/static/media/gallary_1.1820eb133274d8c3faf2.jpeg">
              <img style={{ margin: "1rem", width: "21.1rem" }} alt="img1" src={Gallary_1} />
            </a>




          </LightGallery>
        </div>
      </section>
    </>
  )
}
