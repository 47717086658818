import React, { useEffect, useRef, useState } from 'react'
import Contact from '../assets/images/contact.jpg';
import News_1 from "../assets/images/news1.png"
import { Link } from 'react-router-dom';
import HTTP from "../Http_Api";

export default function History() {
    const [dataHistory, setDataHistory] = useState([]);
    const [dataHistory_inner, setDataHistory_inner] = useState([]);

    const windoSCrollCount = useRef(0)
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

        fetchData_from_database();
    }, [])

    const fetchData_from_database = async () => {
        await HTTP.get('/article/history_page').then((res, req) => {
            setDataHistory(res.data)
        })
        // HTTP.get('/article/history_page').then((res, req) => {
        //     setDataHistory_inner(res.data)
        // })
    }
    return (
        <>
            {Object.keys(dataHistory).map((key) => (
                <>
                    <section id="page-banner">
                        <div className="banner-inner"
                            style={{ backgroundImage: `url(${Contact})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="banner-contents">
                                            <h1 className='d-block'>{dataHistory[key].title}</h1>
                                            <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                                <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                                <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                                                <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                                <Link to={'/'} className="breadcrumbs__item is-active">{dataHistory[key].title}</Link>
                                            </nav>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="history">
                        <div className="container" >
                            <p className='my-5'>{dataHistory[key].long_description}</p>
                        </div>
                    </section>
                </>
            ))}
        </>
    )
}
