import React, { useEffect, useRef, useState } from 'react'
import Teacher from "../assets/images/teacher.jpg"
import Contact from '../assets/images/contact.jpg';
import '../custom_css/Template.css'
import { Link } from 'react-router-dom';
import Http from '../Http_Api';
import { useParams } from 'react-router-dom';
export default function Manage_profile_1() {
    const [dataUser, setDataUser] = useState([]);
    const { id } = useParams();
    const windoSCrollCount = useRef(0)
    
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        getUsers_data();
    }, [])
    const getUsers_data = () => {
        Http.get('/user/' + id).then((res, req) => {
            setDataUser(res.data)
        })
    }
    console.log(dataUser)
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(${Contact})` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>সম্পূর্ণ প্রোফাইল</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Shop</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                        <Link to={'/'} className="breadcrumbs__item is-active">Full Profile</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='Profile_view'>
                <div className="container">

                    <div class="card height-auto">
                        <div class="card-body">
                            <div class="heading-layout1">
                                <div class="item-title">
                                    <h3>About Me</h3>
                                </div>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button"
                                        data-toggle="dropdown" aria-expanded="false">...</a>

                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="#"><i class="fas fa-times text-orange-red"></i>Close</a>
                                        <a class="dropdown-item" href="#"><i class="fas fa-cogs text-dark-pastel-green"></i>Edit</a>
                                        <a class="dropdown-item" href="#"><i class="fas fa-redo-alt text-orange-peel"></i>Refresh</a>
                                    </div>
                                </div>
                            </div>
                            <div class="single-info-details">
                                <div class="item-img">
                                    <img src={dataUser.profile_image_url} alt="teacher" />
                                </div>
                                <div class="item-content">
                                    <div class="header-inline item-header">
                                        <h3 class="text-dark-medium font-medium">{dataUser.name}</h3>
                                        <div class="header-elements">
                                            <ul>
                                                <li><a href="#"><i class="far fa-edit"></i></a></li>
                                                <li><a href="#"><i class="fas fa-print"></i></a></li>
                                                <li><a href="#"><i class="fas fa-download"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p>{dataUser.description}</p>
                                    <div class="info-table table-responsive">
                                        <table class="table text-nowrap">
                                            <tbody>
                                                <tr>
                                                    <td>Name:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Gender:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.gender}</td>
                                                </tr>
                                                <tr>
                                                    <td>Father Name:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.fathers_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mother Name:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.mothers_name}</td>
                                                </tr>
                                                <tr>
                                                    <td>Religion:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.religion}</td>
                                                </tr>
                                                <tr>
                                                    <td>Joining Date:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.created_at}</td>
                                                </tr>
                                                <tr>
                                                    <td>E-mail:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Subject:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.working_subject}</td>
                                                </tr>
                                                <tr>
                                                    <td>Class:</td>
                                                    <td class="font-medium text-dark-medium">2</td>
                                                </tr>
                                                <tr>
                                                    <td>Section:</td>
                                                    <td class="font-medium text-dark-medium">Pink</td>
                                                </tr>
                                                <tr>
                                                    <td>ID No:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.id_no}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.address}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone:</td>
                                                    <td class="font-medium text-dark-medium">{dataUser.phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
