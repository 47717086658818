import React, { useEffect, useState } from 'react';

import Footer_logo from "../assets/images/footer-logo.png";
import Cards from "../assets/images/cards.png";
import Cards_2 from "../assets/images/Cards_2.png"
import Http from '../Http_Api';
export default function Footer() {
    const [data_footer_logo, setData_footer_logo] = useState([]);
    const [data_footer_address, setData_footer_address] = useState([]);
    const [data_footer_copyright, setData_footer_copyright] = useState([]);
    const [data_footer_social, setData_footer_social] = useState([]);
    useEffect(() => {
        get_data_footer_inner();
    }, [])

    const get_data_footer_inner = async () => {
        await Http.get('/article/footer-top/logo').then((res, req) => {
            let value = [];
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            if (value.length > 0) {
                setData_footer_logo(value);
            }
        })

        await Http.get('/article/footer-top/address').then((res, req) => {
            let value = [];
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            if (value.length > 0) {
                setData_footer_address(value);
            }
        })

        await Http.get('/article/footer-bottom/copyright').then((res, req) => {
            let value = [];
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            if (value.length > 0) {
                setData_footer_copyright(value);
            }
        })

        await Http.get('/article/footer-bottom/social').then((res, req) => {
            let value = [];
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            if (value.length > 0) {
                setData_footer_social(value);
            }
        })
    }

    return (
        <>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                {data_footer_logo.map((data) => (
                                    <div key={data.content_id} className="footer-logo">
                                        <a href="index.html"><img src={data.single_link}
                                            alt="" /></a>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="address">
                                    <h5>ঠিকানা</h5>
                                    <ul>
                                        {data_footer_address.map((data) => (
                                            <li key={data.content_id}><i className={data.css_class}></i>{data.title}
                                            </li>
                                        ))}
                                        {/* <li><i className="fa fa-envelope"></i><a
                                            href="mailto:info@markajutalimissunnah.com">info@markajutalimissunnah.com</a></li>
                                        <li><i className="fa fa-phone-alt"></i>(+৮৮) ০১৯৩৩-৭৯৪৬১৬ <br />(+৮৮) ০১৭২১-৫৭১০৪০ </li>
                                        <li><i className="fa fa-globe-asia"></i><a href="#">www.markajutalimissunnah.com</a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="quick-link">
                                    <h5>দ্রত লিঙ্ক</h5>
                                    <ul>
                                        <li><a href="#">আমাদের সম্পর্কে</a></li>
                                        <li><a href="">একাডেমিক</a></li>
                                        <li><a href="">নিউজ এবং ইভেন্ট</a></li>
                                        <li><a href="">অভিভাবগ</a></li>
                                        <li><a href="">জিজ্ঞাসা</a></li>
                                        <li><a href="#">যোগাযোগ</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="cards">
                                    {/* <h5>এই সকল মূল্যপরিশোধ পদ্ধতিতে মূল্যপরিশোধ করা যাবে</h5> */}
                                    <img src={Cards_2}
                                        alt="" />
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="cards">
                                    <h5>এই সকল মূল্যপরিশোধ পদ্ধতিতে মূল্যপরিশোধ করা যাবে</h5>
                                    <img src={Cards}
                                        alt="" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12">
                                {data_footer_copyright.map((data) => (
                                    <div key={data.content_id} className="copyright">
                                        <p>{data.title}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="footer-policy">
                                    <ul>
                                        <li><a href="#">শর্তাতলী</a></li>
                                        <li><a href="#">গোপনীয়তা নীতি</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="socials">
                                    <ul>
                                        {data_footer_social.map((data) => (
                                            <li key={data.content_id}><a href="#"><i className={data.css_class}></i></a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
}
