import React, { version } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// pages importing start
import App from './App';
import Home from './main_pages/Home';
import ContactUs from "./inner_pages/ContactUs"
import Dontaion from "./inner_pages/Donation"
import Read_More from './content_view_pages/Read_more'
import Manage_profile from './content_view_pages/Manage_profile'
import News from "./content_view_pages/News";
import Gallary from './inner_pages/Gallary';
import Calander from "./Calander/calander"
//  pages impoting end

import reportWebVitals from './reportWebVitals';

// css version 4
// import "./assets/css/bootstrap.min.css"
// bootstrap version 4 start
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
// bootstrap version 4 end
// import "bootstrap/dist/js/bootstrap.bundle"
import "./custom_css/main.css"
import "./custom_css/style.css"
import "./custom_css/new_style.css"
import './custom_css/Template.css';
import "./assets/js/all";
import './assets/js/scripts'
// swiper bullets start
import "./custom_css/swiper_bullet.css"
// swiper bullets end
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Manage_profile_1 from './content_view_pages/Manage_profile_1';
import Notice from './inner_pages/Notice';
import AcademicCal from './inner_pages/AcademicCal';
import EventAll from './inner_pages/EventAll';
import NewsAll from './inner_pages/NewsAll';
import News_Event_view from './content_view_pages/News_Event_view';
import History from './inner_pages/History';
import Executive_Director from './inner_pages/Executive_Director';
import Cheif_Director from './inner_pages/Cheif_Director';
import Employee from './inner_pages/Employee';
import Target from './inner_pages/Target';
import Login from "./inner_pages/Login";



const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <><App /></>,
      errorElement: <>error occurs</>,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: '/contactUs',
          element: <ContactUs />,
        },
        {
          path: '/donation',
          element: <Dontaion />,
        },
        {
          path: '/Read_More',
          element: <Read_More />,
        },
        {
          path: '/Manage_profile/:id',
          element: <Manage_profile_1 />,
        },
        {
          path: '/LatestNews',
          element: <News />,
        },
        {
          path: '/gallary',
          element: <Gallary />,
        },
        {
          path: '/NoticeBorde',
          element: <Notice />,
        },
        {
          path: '/EventAll',
          element: <EventAll />,
        },
        {
          path: '/NewsAll',
          element: <NewsAll />,
        },
        {
          path: '/aca_Cal',
          element: <Calander />,
        },
        {
          path: '/News/:id',
          element: <News_Event_view />,
        },
        {
          path: '/Event/:id',
          element: <News_Event_view />,
        },
        {
          path: "/history",
          element: <History />,
        },
        {
          path: "/target",
          element: <Target />,
        },
        {
          path: "/employee",
          element: <Employee />,
        },
        {
          path: "/Chief_Director",
          element: <Cheif_Director />
        },
        {
          path: "/Executive_Director",
          element: <Executive_Director />
        },
      ]
    },
  ],
)

const root = ReactDOM.createRoot(document.getElementById('root'));
// console.log(`${process.env.REACT_APP_API_KEY}`);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
