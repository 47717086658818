import React from 'react';
import { Link } from 'react-router-dom';

export default function News() {
    const Hidden_Vissible = (element, id) => {
        let Myelement = element.target.closest('.btn');
        console.log(Myelement, 'the id is ', id)
        let News_box = document.getElementsByClassName('News_box');
        for (let index = 0; index < News_box.length; index++) {
            const element = News_box[index];
            if (element.classList.contains('d-block')) {
                element.classList.add('d-none');
                element.classList.remove('d-block');
            }
        }
        if (News_box[id].classList.contains('d-none')) {
            News_box[id].classList.add('d-block')
            News_box[id].classList.remove('d-none')
        }
        let mybtn = document.getElementsByClassName('mybtn');
        for (let index = 0; index < mybtn.length; index++) {
            const element = mybtn[index];
            if (element.classList.contains('btn-outline-success')) {
                element.classList.remove('btn-outline-success', 'text-success', 'bg-white')
                element.classList.add('bg-secondary-subtle')
            }
        }
        if (Myelement.classList.contains('bg-secondary-subtle')) {
            Myelement.classList.remove('bg-secondary-subtle')
            Myelement.classList.add('btn-outline-success', 'text-success', 'bg-white')
        }
    }

    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"
                    style={{ backgroundImage: `url('https://source.unsplash.com/random/720x420?newspaper/old')` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>Latest News</h1>
                                    <nav class="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} class="breadcrumbs__item">Shop</Link>
                                        <Link to={'/'} class="breadcrumbs__item">Cart</Link>
                                        <Link to={'/pages/contactUs'} class="breadcrumbs__item is-active">Contact Us</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='blog'>
                <div className="container">
                    <div className="section-title">
                        <h1>Read New News</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="d-flex flex-wrap justify-content-center mb-5">
                                <span style={{ width: '100%' }} className="d-flex justify-content-between  py-3 mb-2 mybtn  font-weight-bold bg-white text-success btn btn-outline-success btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, '0') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i class="fa-solid fa-image"></i>
                                    </span>
                                </span>
                                <span style={{ width: '100%' }} className="d-flex justify-content-between py-3 mb-2 mybtn  font-weight-bold bg-secondary-subtle btn  btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, '1') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i class="fa-solid fa-image"></i>
                                    </span>
                                </span>
                                <span style={{ width: '100%' }} className="d-flex justify-content-between py-3 mb-2 mybtn  font-weight-bold bg-secondary-subtle btn  btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, '2') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i class="fa-solid fa-image"></i>
                                    </span>
                                </span>
                                <span style={{ width: '100%' }} className="d-flex justify-content-between py-3 mb-2 mybtn  font-weight-bold bg-secondary-subtle btn  btn-lg mx-2 my-2" onClick={(e) => { Hidden_Vissible(e, '3') }}>
                                    <span>Large Button</span>
                                    <span>
                                        <i class="fa-solid fa-image"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="News_main_box col-lg-8 text-left">
                            <div id='0' className='News_box d-block'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?card" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 1</h3>
                                        <p className='pe-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                            <div id='1' className='News_box d-none'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?newspaper" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 2</h3>
                                        <p className='ps-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                            <div id='2' className='News_box d-none'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?report" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 3</h3>
                                        <p className='pe-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                            <div id='3' className='News_box d-none'>
                                <div className='d-flex flex-column fadeIN'>
                                    <div className=''>
                                        <img src="https://source.unsplash.com/random/780x360?news" alt="" />
                                    </div>
                                    <div className='pe-5 mt-4'>
                                        <h3>Heading 4</h3>
                                        <p className='pe-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quibusdam, fugiat modi veniam illum tempore quam officiis rem porro laboriosam nostrum at impedit temporibus repellendus dolore eligendi omnis neque explicabo dolor quas nihil labore? Eligendi ex maxime alias rem mollitia temporibus! Alias magni minus exercitationem eaque quos aut ipsum laboriosam!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
