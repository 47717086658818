import React, { useEffect, useRef, useState } from 'react'
import Hero_1 from "../assets/images/hero1.png";
import Counter from "../assets/images/counter.png";
import News_1 from "../assets/images/news1.png";
import D_1 from "../assets/images/d1.png";
import S_1 from "../assets/images/s1.png";
import Team_1 from '../assets/images/team1.png'
import Team_2 from '../assets/images/team2.png'
import Team_3 from '../assets/images/team3.png'
import Testimonial_1 from '../assets/images/testimonial1.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import { Link, json } from 'react-router-dom';
import Http from '../Http_Api';
import { formToJSON } from 'axios';
export default function Home() {
    const windoSCrollCount = useRef(0)
    const currenttime = useRef(6)
    const base_bulian = useRef(false);
    const sno = useRef(null)
    let user_catagory = useRef('advisoryBorde');
    const [data_all_employee, setData_all_employee] = useState([]);
    const [dataCrousel, setDataCrousel] = useState([]);
    const [dataDiffrences_title, setDataDiffrences_title] = useState([]);
    const [dataDiffrences_inner, setDataDiffrences_inner] = useState([]);
    const [dataServices_title, setDataServices_title] = useState([]);
    const [dataServices_inner, setDataServices_inner] = useState([]);
    const [dataCounter, setDataCounter] = useState([]);
    const [dataMotive_title, setDataMotive_title] = useState([])
    const [dataMotive_inner, setDataMotive_inner] = useState([])
    const [dataTeam_title, setDataTeam_title] = useState([])
    // const [dataTeam_advisoryBorde, setDataTeam_advisoryBorde] = useState([])
    // const [dataTeam_official, setDataTeam_official] = useState([])
    // const [dataTeam_teacher, setDataTeam_teacher] = useState([])
    // const [dataTeam_employee, setDataTeam_employee] = useState([]);
    const [dataTestimonial_title, setDataTestimonial_title] = useState([])
    const [dataTestimonial_inner, setDataTestimonial_inner] = useState([])
    const [dataNews_title, setDataNews_title] = useState([])
    const [dataNews_inner, setDataNews_inner] = useState([])
    const [dataEvent_title, setDataEvent_title] = useState([])
    const [dataEvent_inner, setDataEvent_inner] = useState([])
    useEffect(() => {
        // fetch_data_from_database();
        if (windoSCrollCount.current === 0) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop

        // console.log(scrolling)
        data_crousel();
        data_difference_title();
        data_difference_inner();
        data_services_title();
        data_services_inner();
        data_motive_title();
        data_motive_inner();
        data_counter();
        data_team_title();
        get_all_employee();
        data_news_title();
        data_news_inner();
        data_tesimonial_title();
        data_tesimonial_inner();
        data_event_title();
        data_event_inner();



    }, [])

    const data_crousel = async () => {
        await Http.get('/article/crousel').then((res, req) => {
            setDataCrousel(res.data)
        })
    }
    const data_difference_title = async () => {
        await Http.get('/article/diffrences/title').then((res, req) => {
            setDataDiffrences_title(res.data)
        })
    }
    const data_difference_inner = async () => {
        await Http.get('/article/diffrences/inner_content').then((res, req) => {
            setDataDiffrences_inner(res.data)
        })
    }
    const data_services_title = async () => {
        await Http.get("/article/services/title").then((res, req) => {
            setDataServices_title(res.data)
        })
    }
    const data_services_inner = async () => {
        await Http.get('/article/services/inner_content').then((res, req) => {
            setDataServices_inner(res.data)
        })
    }

    const data_counter = async () => {

        await Http.get('/article/counter').then((res, req) => {
            setDataCounter(res.data)
        })
    }

    const data_motive_title = async () => {
        await Http.get('/article/motive/title').then((res, req) => {
            setDataMotive_title(res.data)
        })
    }

    const data_motive_inner = async () => {
        await Http.get('/article/motive/inner_content').then((res, req) => {
            setDataMotive_inner(res.data)
        })
    }

    const data_team_title = async () => {
        await Http.get('/article/team/title').then((res, req) => {
            setDataTeam_title(res.data)
        })
    }
    const get_all_employee = async () => {
        if (user_catagory.current === "advisoryBorde") {
            console.log(user_catagory.current)
            await Http.get('/users/worker/advisoryBorde').then((res, req) => {
                let value = []
                Object.keys(res.data).map((key) => {
                    value.push(res.data[key]);
                })
                setData_all_employee(value)
                // console.log(value)
            })
        }
        else {
            await Http.get('/users/worker/' + user_catagory.current).then((res, req) => {
                let value = []
                Object.keys(res.data).map((key) => {
                    value.push(res.data[key]);
                })
                setData_all_employee(value)
                // console.log(value)
            })
        }

    }
    const data_news_title = async () => {

        await Http.get('/article/news_page/title').then((res, req) => {
            setDataNews_title(res.data)
        })
    }
    const data_news_inner = async () => {
        await Http.get('/news').then((res, req) => {
            setDataNews_inner(res.data)
        })
    }

    const data_tesimonial_title = async () => {
        await Http.get('/article/testimonial/title').then((res, req) => {
            setDataTestimonial_title(res.data)
        })
    }
    const data_tesimonial_inner = async () => {
        await Http.get('/article/testimonial/inner_content').then((res, req) => {
            setDataTestimonial_inner(res.data)
        })
    }
    const data_event_title = async () => {
        await Http.get('/article/event_page/title').then((res, req) => {
            setDataEvent_title(res.data)
        })
    }
    const data_event_inner = async () => {
        await Http.get(`/event`).then((res, req) => {
            setDataEvent_inner(res.data)
        })
    }


    const Team_vissible = (e, name) => {
        let element = e.target.closest('.tablinks')
        let tablinks = document.getElementsByClassName('tablinks')
        for (let index = 0; index < tablinks.length; index++) {
            const element = tablinks[index];
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            }
        }
        if (!element.classList.contains('active')) {
            element.classList.add('active');
        }


        let employee = document.getElementById(name);
        let employee_all = document.getElementsByClassName('team_member_by_catagory');
        for (let index = 0; index < employee_all.length; index++) {
            const element = employee_all[index];
            if (element.classList.contains('d-block')) {
                element.classList.add('d-none')
                element.classList.remove('d-block')
            }
        }
        // setUser_catagory(name);
        if (employee.classList.contains('d-none')) {
            employee.classList.add('d-block')
            employee.classList.remove('d-none')
        }
        user_catagory.current = name
        get_all_employee();
    }


    // const fetch_data_from_database = () => {
    //     Http.get('/article/crousel').then((res, req) => {
    //         setDataCrousel(res.data)
    //     })

    //     Http.get('/article/diffrences/title').then((res, req) => {
    //         setDataDiffrences_title(res.data)
    //     })

    //     Http.get('/article/diffrences/inner_content').then((res, req) => {
    //         setDataDiffrences_inner(res.data)
    //     })

    //     Http.get("/article/services/title").then((res, req) => {
    //         setDataServices_title(res.data)
    //     })

    //     Http.get('/article/services/inner_content').then((res, req) => {
    //         setDataServices_inner(res.data)
    //     })

    //     Http.get('/article/counter').then((res, req) => {
    //         setDataCounter(res.data)
    //     })

    //     Http.get('/article/motive/title').then((res, req) => {
    //         setDataMotive_title(res.data)
    //     })

    //     Http.get('/article/motive/inner_content').then((res, req) => {
    //         setDataMotive_inner(res.data)
    //     })

    //     Http.get('/article/team/title').then((res, req) => {
    //         setDataTeam_title(res.data)
    //     })

    //     Http.get('/article/team/advisoryBorde').then((res, req) => {
    //         setDataTeam_advisoryBorde(res.data)
    //     })

    //     Http.get('/article/team/official').then((res, req) => {
    //         setDataTeam_official(res.data)
    //     })

    //     Http.get('/article/team/teacher').then((res, req) => {
    //         setDataTeam_teacher(res.data)
    //     })

    //     Http.get('/article/team/employee').then((res, req) => {
    //         setDataTeam_employee(res.data)
    //     })

    //     Http.get('/article/testimonial/title').then((res, req) => {
    //         setDataTestimonial_title(res.data)
    //     })

    //     Http.get('/article/testimonial/inner_content').then((res, req) => {
    //         setDataTestimonial_inner(res.data)
    //     })

    //     Http.get('/article/news_page/title').then((res, req) => {
    //         setDataNews_title(res.data)
    //     })

    //     Http.get('/article/event_page/title').then((res, req) => {
    //         setDataEvent_title(res.data)
    //     })

    //     Http.get('/news').then((res, req) => {
    //         setDataNews_inner(res.data)
    //     })

    //     Http.get(`/event`).then((res, req) => {
    //         setDataEvent_inner(res.data)
    //     })
    // }


    // const Team_vissible = (evt, cityName) => {
    //     let i, tabcontent, tablinks;
    //     tabcontent = document.getElementsByClassName("tabcontent");
    //     for (i = 0; i < tabcontent.length; i++) {
    //         tabcontent[i].style.display = "none";
    //     }
    //     tablinks = document.getElementsByClassName("tablinks");
    //     for (i = 0; i < tablinks.length; i++) {
    //         tablinks[i].className = tablinks[i].className.replace(" active", "");
    //     }
    //     document.getElementById(cityName).style.display = "block";
    //     evt.currentTarget.className += " active";

    // }

    const Load_More_Service = (e) => {
        let cta_btn = e.target.closest('#cta-btn');
        // let wrapper = document.getElementsByClassName('wrapper')
        let b = false
        // let currenttime = 3
        let wrapper = document.querySelectorAll('.container .row .wrapper');
        if (base_bulian.current == false) {
            for (let i = currenttime.current; i < currenttime.current + 3; i++) {
                if (wrapper[i] != null) {
                    wrapper[i].style.display = 'inline-block'
                }
            }
            currenttime.current = currenttime.current + 3;
            if (currenttime.current === 9) {
                base_bulian.current = true
                cta_btn.lastElementChild.innerText = 'প্রদর্শন কম দেখুন';
            }
        }

        else if (base_bulian.current === true) {
            // console.log('hell')
            if (currenttime.current >= 3) {
                for (let inf = currenttime.current; inf > currenttime.current - 4; inf--) {
                    if (wrapper[inf] != null) {
                        wrapper[inf].style.display = 'none';
                    }
                    // console.log(inf)
                }
                currenttime.current = currenttime.current - 3

                if (currenttime.current == 3) {
                    base_bulian.current = false
                    cta_btn.lastElementChild.innerText = 'আমাদের পরিসেবা আরো দেখুন';
                }
            }
        }
        // console.log(currenttime.current, b)

    }

    let short_data_news_inner = []
    let short_data_event_inner = [];
    if (dataNews_inner.length > 0) {
        for (let index = 0; index < 3; index++) {
            const element = dataNews_inner[index];
            short_data_news_inner.push(element)
            // console.log(element)
        }
    }
    if (dataEvent_inner.length > 0) {
        for (let index = 1; index < 4; index++) {
            const element = dataEvent_inner[index];
            short_data_event_inner.push(element);
        }
    }



    const Catagory_data_list = ['advisoryBorde', 'official', 'teacher', 'employee'];
    return (
        <>
            <section id="hero">
                <div className="hero-carousel owl-carousel">
                    <Swiper
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: true
                        }}
                        speed={1500}
                        className="mySwiper"
                        pagination={{
                            el: '.swiper-pagination',
                            clickable: true,
                            renderBullet: function (index, className) {
                                return '<span class="' + className + ' my_sigle_bullet_white"></span>';
                            },
                        }}
                        effect={'fade'}
                        modules={[Autoplay, Pagination, EffectFade]}
                    >
                        {Object.keys(dataCrousel).map((key) => (
                            <SwiperSlide key={dataCrousel[key].content_id}>
                                <div className="single-hero-item"
                                    style={{ backgroundImage: `url(${dataCrousel[key].single_link})` }}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="hero-contents">
                                                    <h1 className='fadeinleft'>{dataCrousel[key].title}</h1>
                                                    <p className='fadeinright'>{dataCrousel[key].long_description}</p>
                                                    <ul className='fadeindown'>
                                                        {Object.keys((dataCrousel[key].short_description).split(/,+/)).map((key1) => (
                                                            <li><a href="#">{dataCrousel[key].short_description.split(/,+/)[key1]}</a></li>
                                                        ))}
                                                        {/* <li><a href="#">ভর্তি সম্পর্কে</a></li> */}

                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                        {/* <SwiperSlide>
                            <div className="single-hero-item"
                                style={{ backgroundImage: `url(${Hero_1})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-contents">
                                                <h1 className='fadeinleft'>কওমী ও আধুনিক শিক্ষার সমন্বয়ে একটি স্বতন্ত্র দ্বীনি শিক্ষা প্রতিষ্ঠান</h1>
                                                <p className='fadeinright'>মারকাযু তা’লীমিস সুন্নাহ জামালপুর। পথ চলার ৪র্থ বছর। ইলমে ওহী ও ইলমে নববীর অন্বেষণে
                                                    উজ্জীবিত হয়ে এর পথচলা। যুগপোযোগী ও সৃজনশীল শিক্ষাদান পদ্ধতি প্রতিষ্ঠানটির অনন্য
                                                    অলংকার। এলেম ও আমলের সমন্বয়ে উন্নততর জীবন গঠনের অনুপম অনুশীলন কেন্দ্র। শিশু শ্রেণি,
                                                    নাজেরা, হিফজুল কুরআন ও কিতাব বিভাগ দিয়ে সাজানো হয়েছে অত্র প্রতিষ্ঠানের শিক্ষা পরিসর।
                                                    আসাতিযায়ে কেরামের সার্বক্ষণিক নেগরানির মাধ্যমে আকাবির ও আসলাফের যোগ্য উত্তরসুরি
                                                    হিসেবে যুগ সচেতন আলেমে-দ্বীন তৈরী করার লক্ষ্যে এক নিরলস প্রচেষ্টা চালিয়ে যাচ্ছে
                                                    প্রতিষ্ঠানটি। </p>
                                                <ul className='fadeindown'>
                                                    <li><a href="#">অনুদান</a></li>
                                                    <li><a href="#">ভর্তি সম্পর্কে</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-hero-item"
                                style={{ backgroundImage: `url(https://source.unsplash.com/random/720x720/?islamic)` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-contents">
                                                <h1 className='fadeinleft'>কওমী ও আধুনিক শিক্ষার সমন্বয়ে একটি স্বতন্ত্র দ্বীনি শিক্ষা প্রতিষ্ঠান</h1>
                                                <p className='fadeinright'>মারকাযু তা’লীমিস সুন্নাহ জামালপুর। পথ চলার ৪র্থ বছর। ইলমে ওহী ও ইলমে নববীর অন্বেষণে
                                                    উজ্জীবিত হয়ে এর পথচলা। যুগপোযোগী ও সৃজনশীল শিক্ষাদান পদ্ধতি প্রতিষ্ঠানটির অনন্য
                                                    অলংকার। এলেম ও আমলের সমন্বয়ে উন্নততর জীবন গঠনের অনুপম অনুশীলন কেন্দ্র। শিশু শ্রেণি,
                                                    নাজেরা, হিফজুল কুরআন ও কিতাব বিভাগ দিয়ে সাজানো হয়েছে অত্র প্রতিষ্ঠানের শিক্ষা পরিসর।
                                                    আসাতিযায়ে কেরামের সার্বক্ষণিক নেগরানির মাধ্যমে আকাবির ও আসলাফের যোগ্য উত্তরসুরি
                                                    হিসেবে যুগ সচেতন আলেমে-দ্বীন তৈরী করার লক্ষ্যে এক নিরলস প্রচেষ্টা চালিয়ে যাচ্ছে
                                                    প্রতিষ্ঠানটি। </p>
                                                <ul className='fadeindown'>
                                                    <li><a href="#">অনুদান</a></li>
                                                    <li><a href="#">ভর্তি সম্পর্কে</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="single-hero-item"
                                style={{ backgroundImage: `url(https://source.unsplash.com/random/720x720/?nature)` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="hero-contents">
                                                <h1 className='fadeinleft'>কওমী ও আধুনিক শিক্ষার সমন্বয়ে একটি স্বতন্ত্র দ্বীনি শিক্ষা প্রতিষ্ঠান</h1>
                                                <p className='fadeinright'>মারকাযু তা’লীমিস সুন্নাহ জামালপুর। পথ চলার ৪র্থ বছর। ইলমে ওহী ও ইলমে নববীর অন্বেষণে
                                                    উজ্জীবিত হয়ে এর পথচলা। যুগপোযোগী ও সৃজনশীল শিক্ষাদান পদ্ধতি প্রতিষ্ঠানটির অনন্য
                                                    অলংকার। এলেম ও আমলের সমন্বয়ে উন্নততর জীবন গঠনের অনুপম অনুশীলন কেন্দ্র। শিশু শ্রেণি,
                                                    নাজেরা, হিফজুল কুরআন ও কিতাব বিভাগ দিয়ে সাজানো হয়েছে অত্র প্রতিষ্ঠানের শিক্ষা পরিসর।
                                                    আসাতিযায়ে কেরামের সার্বক্ষণিক নেগরানির মাধ্যমে আকাবির ও আসলাফের যোগ্য উত্তরসুরি
                                                    হিসেবে যুগ সচেতন আলেমে-দ্বীন তৈরী করার লক্ষ্যে এক নিরলস প্রচেষ্টা চালিয়ে যাচ্ছে
                                                    প্রতিষ্ঠানটি। </p>
                                                <ul className='fadeindown'>
                                                    <li><a href="#">অনুদান</a></li>
                                                    <li><a href="#">ভর্তি সম্পর্কে</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                        <div className='swiper-pagination my_swiper_pagination'></div>
                    </Swiper>
                </div>
            </section>


            <section id="diffrences">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {Object.keys(dataDiffrences_title).map((key) => (
                                <div className="section-title">
                                    <h2>{dataDiffrences_title[key].title}</h2>
                                    <p>{dataDiffrences_title[key].short_description}</p>
                                </div>
                            ))}
                            <div className="">
                                <Swiper
                                    speed={3000}
                                    loop={true}
                                    slidesPerView={1}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        // when window width is >= 640px
                                        640: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 768px
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1010: {
                                            slidesPerView: 3,
                                        }
                                    }}
                                    className="mySwiper"
                                >
                                    {Object.keys(dataDiffrences_inner).map((key) => (
                                        <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                            <div className="single-difference-item">
                                                <img src={dataDiffrences_inner[key].single_link}
                                                    alt="" />
                                                <h3>{dataDiffrences_inner[key].title}</h3>
                                                <p>{dataDiffrences_inner[key].long_description}</p>
                                                <Link to={dataDiffrences_inner[key].multiple_link}>{dataDiffrences_inner[key].short_description}</Link>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                    {/* <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>মানসম্পূন্ন শিক্ষা ব্যবস্থা</h3>
                                            <p>যোগ্য, চরিত্রবান, ইজাজাহপ্রাপ্ত ও প্রশিক্ষিত শিক্ষকবৃন্দের মাধ্যমে পাঠদান করা হয়।
                                                শিক্ষাদানের ক্ষেত্রে ইজরা, তামরীন, মশক ও প্রচুর অনুশীলনী পদ্ধতির মাধ্যমে পড়া</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide style={{ paddingBottom: '2rem' }}>
                                        <div className="single-difference-item">
                                            <img src={D_1}
                                                alt="" />
                                            <h3>সমন্বিত সিলেবাস</h3>
                                            <p>আল কোরান শিক্ষার পাশাপাশি আরবী, উদূ, ফার্সী, বাংলা, ইংরেজী, গনিত, কম্পিউটার সহ মৌলিক
                                                বিষয়গুলো গুরুত্বের সাথে শ্রেণিভিত্তক নিয়মিত পাঠদান করা হয়।</p>
                                            <Link to={"/LatestNews"}>বিস্তারিত পড়–ন</Link>
                                        </div>
                                    </SwiperSlide> */}
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="services">
                <div className="container">
                    {Object.keys(dataServices_title).map((key) => (
                        <div className="section-title">
                            <h2>{dataServices_title[key].title}</h2>
                        </div>
                    ))}
                    <div className="row"
                        id="row">
                        {Object.keys(dataServices_inner).map((key) => (
                            <div className="col-lg-4 col-md-4 col-12 wrapper"
                                style={{ display: `${dataServices_inner[key].css_class == null ? 'block' : dataServices_inner[key].css_class}` }} >

                                <div className="single-services">
                                    <div className="services-icon">
                                        <img src={dataServices_inner[key].single_link}
                                            alt="" />
                                    </div>
                                    <div className="services-contents">
                                        <p>{dataServices_inner[key].title}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="col-lg-4 col-md-4 col-12 wrapper">
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper">
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" >
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" style={{ display: 'none' }}>
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" style={{ display: 'none' }}>
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 wrapper" style={{ display: 'none' }}>
                            <div className="single-services">
                                <div className="services-icon">
                                    <img src={S_1}
                                        alt="" />
                                </div>
                                <div className="services-contents">
                                    <p>আধুনিক, নিরাপদ ও উন্নতমানের হোস্টেল ব্যবস্থাপনা</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="align-middle">
                        <div id="cta-btn" onClick={(e) => { Load_More_Service(e) }}
                            className="cta-btn">
                            <a href="#services">আমাদের পরিসেবা আরো দেখুন</a>
                        </div>
                    </div>
                </div>
            </section >


            <section id="counter">
                <div className="conter-inner"
                    style={{ backgroundImage: `url(${Counter})` }}>
                    <div className="container">
                        <div className="row">
                            {Object.keys(dataCounter).map((key) => (
                                <div className="col-md-4">
                                    <div className="single-counter">
                                        <p><strong>{dataCounter[key].title}</strong></p>
                                        <p>{dataCounter[key].short_description}</p>
                                    </div>
                                </div>
                            ))}
                            {/* <div className="col-md-4">
                                <div className="single-counter">
                                    <p><strong>১০</strong></p>
                                    <p>শিক্ষক</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="single-counter">
                                    <p><strong>২</strong></p>
                                    <p>কর্মচারী</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>


            <section id="motive">
                <div className="container">
                    <div className="row">
                        {Object.keys(dataMotive_title).map((key) => (
                            <div className="col-lg-7">
                                <div className="motive-head">
                                    <h2>{dataMotive_title[key].title}</h2>
                                </div>
                            </div>
                        ))}
                        {Object.keys(dataMotive_inner).map((key) => (
                            <div className="col-lg-5">
                                <div className="motive-contents">
                                    <h3>{dataMotive_inner[key].title}</h3>
                                    <p>{dataMotive_inner[key].short_description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section id="team">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 mx-auto">
                            {Object.keys(dataTeam_title).map((key) => (
                                <div className="section-title">
                                    <h2>{dataTeam_title[key].title}</h2>
                                    <p>{dataTeam_title[key].short_description}</p>
                                    <div className="tab">
                                        <span className="tablinks btn btn-outline-light mx-2  active"
                                            onClick={(e) => { Team_vissible(e, 'advisoryBorde') }}>উপদেষ্টামন্ডলী</span>
                                        <span className="tablinks btn btn-outline-light mx-2"
                                            onClick={(e) => { Team_vissible(e, 'official') }}>কর্মকর্তা</span>
                                        <span className="tablinks btn btn-outline-light mx-2"
                                            onClick={(e) => { Team_vissible(e, 'teacher') }}>শিক্ষক</span>
                                        <span className="tablinks btn btn-outline-light mx-2"
                                            onClick={(e) => { Team_vissible(e, 'employee') }}>কর্মচারী</span>
                                    </div >
                                </div >
                            ))}
                        </div >

                        <div className='col-12 col-md-12 mx-auto'>
                            {Catagory_data_list.map((data) => (
                                <div id={data} className={"team_member_by_catagory " + (data == 'advisoryBorde' ? 'd-block' : 'd-none')}>
                                    <div className="row ">
                                        {data_all_employee.length > 0 ? data_all_employee.map((data) => (
                                            <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                                <div className="single-team">
                                                    <div className="team-image">
                                                        <img src={data.profile_image_url}
                                                            alt="" />
                                                    </div>
                                                    <div className="team-member-details">
                                                        <h4>{data.name}</h4>
                                                        <p>{data.profession}</p>
                                                        <Link to={'/Manage_profile/' + data.id}>সম্পূর্ণ প্রোফাইল</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : ''}
                                        {/* <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                            ))}
                            {/* <div className="tabcontent" id="advisoryBorde">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={20}
                                    grabCursor={true}
                                    loop={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                        renderBullet: function (index, className) {
                                            return '<span class="' + className + ' my_sigle_bullet_color_1"></span>';
                                        },
                                    }}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        
                                        640: {
                                            slidesPerView: 2,
                                        },

                                       
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    className="mySwiper">
                                    {Object.keys(dataTeam_advisoryBorde).map((key2) => (

                                        <SwiperSlide style={{ paddingBottom: "3rem" }} >

                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={dataTeam_advisoryBorde[key2].single_link}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{dataTeam_advisoryBorde[key2].title}</h4>
                                                    <p>{dataTeam_advisoryBorde[key2].short_description}</p>
                                                    <Link to={'/Manage_profile'}>{dataTeam_advisoryBorde[key2].long_description}</Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                    ))}

                                 
                                    <div className="swiper-pagination"></div>
                                </Swiper>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section >


            <section id="news" >
                <div className="container">
                    {Object.keys(dataNews_title).map((key) => (
                        <div className="section-title">
                            <h2>{dataNews_title[key].title}</h2>
                            <p>{dataNews_title[key].short_description}</p>
                        </div>
                    ))}
                    <div className="row">
                        {short_data_news_inner.length > 0 ? (short_data_news_inner.map((data, index) => (
                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="single-news">
                                    <div className="news-image"
                                        style={{ backgroundImage: `url(${data.img_url})` }}></div>
                                    <div className="news-details">
                                        <h4>{data.title}</h4>
                                        <p>{data.description.slice(0, 180)} ...</p>
                                        <Link to={"/News/" + data.id}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        ))) : ''}
                        {/* <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>
                                <div className="news-details">
                                    <h4>Two More Removed</h4>
                                    <p>In its bid to crackdown on tax avoidance and evasion, the European Union has blacklisted
                                        and “grey-listed”</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="single-news">
                                <div className="news-image"
                                    style={{ backgroundImage: `url(${News_1})` }}></div>
                                <div className="news-details">
                                    <h4>Changes to UAE</h4>
                                    <p>The United Arab Emirates, which previously did not allow 100% ownership of foreign
                                        business entities or long residency</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section >


            <section id="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                {Object.keys(dataTestimonial_title).map((key) => (
                                    <h2>{dataTestimonial_title[key].title}</h2>
                                ))}
                            </div>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={20}
                                grabCursor={true}
                                loop={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                    renderBullet: function (index, className) {
                                        return '<span class="' + className + ' my_sigle_bullet"></span>';
                                    },
                                }}
                                modules={[Pagination]}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        slidesPerView: 2,
                                    },
                                }}
                                className="mySwiper">
                                {Object.keys(dataTestimonial_inner).map((key) => (
                                    <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                        <div className="single-testimonial">
                                            <div className="testimonial-author">
                                                <img src={dataTestimonial_inner[key].single_link}
                                                    alt="" />
                                            </div>
                                            <div className="testimonial-autor-details">
                                                <h4>{dataTestimonial_inner[key].title}</h4>
                                                <p>{dataTestimonial_inner[key].short_description}</p>
                                            </div>
                                            <div className="testimonial-contents">
                                                <p><i>{dataTestimonial_inner[key].long_description}</i>
                                                </p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                                {/* <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                    <div className="single-testimonial">
                                        <div className="testimonial-author">
                                            <img src={Testimonial_1}
                                                alt="" />
                                        </div>
                                        <div className="testimonial-autor-details">
                                            <h4>Richard Smith</h4>
                                            <p>Co-Founder, Company</p>
                                        </div>
                                        <div className="testimonial-contents">
                                            <p><i>Far far away, behind the word mountains, far from the countries Vokalia and
                                                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove
                                                right at the coast of the Semantics, a large language ocean. A small river named
                                                Duden flows by their place and supplies it with the necessary regelialia.</i>
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                    <div className="single-testimonial">
                                        <div className="testimonial-author">
                                            <img src={Testimonial_1}
                                                alt="" />
                                        </div>
                                        <div className="testimonial-autor-details">
                                            <h4>Richard Smith</h4>
                                            <p>Co-Founder, Company</p>
                                        </div>
                                        <div className="testimonial-contents">
                                            <p><i>Far far away, behind the word mountains, far from the countries Vokalia and
                                                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove
                                                right at the coast of the Semantics, a large language ocean. A small river named
                                                Duden flows by their place and supplies it with the necessary regelialia.</i>
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide style={{ paddingBottom: '3rem' }}>
                                    <div className="single-testimonial">
                                        <div className="testimonial-author">
                                            <img src={Testimonial_1}
                                                alt="" />
                                        </div>
                                        <div className="testimonial-autor-details">
                                            <h4>Richard Smith</h4>
                                            <p>Co-Founder, Company</p>
                                        </div>
                                        <div className="testimonial-contents">
                                            <p><i>Far far away, behind the word mountains, far from the countries Vokalia and
                                                Consonantia, there live the blind texts. Separated they live in Bookmarksgrove
                                                right at the coast of the Semantics, a large language ocean. A small river named
                                                Duden flows by their place and supplies it with the necessary regelialia.</i>
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                                <div className="swiper-pagination"></div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section id="blog">
                <div className="container text-center">
                    {Object.keys(dataEvent_title).map((key) => (
                        <div className="section-title">
                            <h2>{dataEvent_title[key].title}</h2>
                            <p>{dataEvent_title[key].short_description}</p>
                        </div>
                    ))}

                    <div className="row mb-5">
                        <div className="col-12 col-lg-7 ">
                            <img className='single-news' src={dataEvent_inner.length > 0 ? dataEvent_inner[0].img_url : ''} />
                        </div>
                        <div className="col-12 col-lg-5 text-left  px-4">
                            <h2 className='font-weight-bold mt-2'>{dataEvent_inner.length > 0 ? dataEvent_inner[0].title : ''}</h2>
                            <p className='mt-4'>{dataEvent_inner.length > 0 ? dataEvent_inner[0].description.slice(0, 290) : ''} ...</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                    <div className='row pt-2 text-left'>
                        {short_data_event_inner.length > 0 ? (short_data_event_inner).map((data, index) => (
                            <div className="col-12 col-md-4 col-lg-4 ">
                                <div className="card single-news" style={{ width: 'auto' }}>
                                    <img src={data.img_url} className="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title">{data.title}</h5>
                                        <p className="card-text">{data.description.slice(0, 180)} ...</p>
                                        <Link to={"/Event/" + data.id} className="btn btn-primary">Go somewhere</Link>
                                    </div>
                                </div>
                            </div>
                        )) : ''}
                        {/* <div className="col-12 col-md-4 col-lg-4 ">
                            <div className="card single-news" style={{ width: 'auto' }}>
                                <img src="https://source.unsplash.com/random/360x260?science,nature" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 ">
                            <div className="card single-news" style={{ width: 'auto' }}>
                                <img src="https://source.unsplash.com/random/360x260?science" className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <a href="#" className="btn btn-primary">Go somewhere</a>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>


        </>
    )
}
