import React, { useEffect, useRef, useState } from 'react'
import Contact from "../assets/images/contact.jpg";
import { Link } from 'react-router-dom';
import Http from '../Http_Api';
export default function ContactUs() {
    const windoSCrollCount = useRef(0)
    const [dataContactUs_title, setDataContactUs_title] = useState([])
    const [dataContactUs_inner, setDataContactUs_inner] = useState([])
    const [dataContactUs_map, setDataContactUs_map] = useState([])
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        fetch_data_from_database()
        if (windoSCrollCount.current === 0) {
            console.log('asdfasfsa')
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }

    }, [])

    const fetch_data_from_database = async () => {
        await Http.get('/article/contactUs_page/title').then((res, req) => {
            setDataContactUs_title(res.data);
        })
        await Http.get('/article/contactUs_page/inner_content').then((res, req) => {
            setDataContactUs_inner(res.data);
        })
        await Http.get('/article/contactUs_page/location_map').then((res, req) => {
            setDataContactUs_map(res.data);
        })
    }
    return (
        <>

            <section id="page-banner">
                <div className="banner-inner"
                    style={{ backgroundImage: `url(${Contact})` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {Object.keys(dataContactUs_title).map((key) => (
                                    <div key={dataContactUs_title[key].content_id} className="banner-contents">
                                        <h1 className='d-block'>{dataContactUs_title[key].title}</h1>
                                        <nav class="breadcrumbs " style={{ opacity: "1" }}>
                                            <Link to={'/'} class="breadcrumbs__item"><i class="text-black fa-solid fa-house"></i> Home</Link>
                                            <Link to={'/'} class="breadcrumbs__item">Shop</Link>
                                            <Link to={'/'} class="breadcrumbs__item">Cart</Link>
                                            <Link to={'/contactUs'} class="breadcrumbs__item is-active">{dataContactUs_title[key].title}</Link>
                                        </nav>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="boxs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="grid-boxs">
                                {Object.keys(dataContactUs_inner).map((key) => (
                                    <div key={dataContactUs_inner[key].content_id} className="single-grid-box">
                                        <div className="box-icon">
                                            <i className={dataContactUs_inner[key].single_link}></i>
                                        </div>
                                        <div className="box-contents">
                                            <h3>{dataContactUs_inner[key].title}</h3>
                                            <ul>
                                                <li>{dataContactUs_inner[key].short_description}</li>
                                                <li>{dataContactUs_inner[key].long_description}</li>
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className="single-grid-box">
                                    <div className="box-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="box-contents">
                                        <h3>মোবাইল</h3>
                                        <ul>
                                            <li>শিক্ষা সচিব : (+৮৮) ০১৯৩৩-৭৯৪৬১৬</li>
                                            <li>নির্বাহী পরিচালক : (+৮৮) ০১৭২১-৫৭১০৪০</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single-grid-box">
                                    <div className="box-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="box-contents">
                                        <h3>ই-মেইল</h3>
                                        <ul>
                                            <li><a href="mailto:info@markajutalimisunnah.com">info@markajutalimisunnah.com</a>
                                            </li>
                                            <li><a
                                                href="mailto:contact@markajutalimisunnah.com">contact@markajutalimisunnah.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single-grid-box">
                                    <div className="box-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="box-contents">
                                        <h3>ঠিকানা</h3>
                                        <ul>
                                            <li>রশিদপুর, জামালপুর পৌরসভা, জামালপর সদর,</li>
                                            <li>জামালপুর - ২০০০, বাংলাদেশ</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single-grid-box">
                                    <div className="box-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="box-contents">
                                        <h3>ক্লাসে সময়</h3>
                                        <ul>
                                            <li>শনি-বৃহ: সকাল ৮ টা থেকে বিকাল ৪ টা</li>
                                            <li>শুক্র: সকাল ৮ থেকে সকাল ১০ টা</li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="map">
                <div className="map-iframe">
                    {Object.keys(dataContactUs_map).map((key) => (
                        <iframe key={dataContactUs_map[key].content_id} title='map'
                            src={dataContactUs_map[key].single_link}
                            width=""
                            height=""
                            style={{ border: '0' }}
                            allowfullscreen=""
                            loading="lazy"></iframe>
                    ))}
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="contact-form-inner">
                                <h4>আমাদের সাথে যোগাযোগ</h4>
                                <div className="contact-form">
                                    <div className="single-input">
                                        <input type="text"
                                            placeholder="Name" />
                                    </div>
                                    <div className="single-input">
                                        <input type="email"
                                            placeholder="Email" />
                                    </div>
                                    <div className="single-input">
                                        <input type="text"
                                            placeholder="Subject" />
                                    </div>
                                    <div className="single-input">
                                        <textarea name=""
                                            id=""
                                            cols=""
                                            rows=""
                                            placeholder="Message"></textarea>
                                    </div>
                                    <div className="single-input-btn">
                                        <input type="submit"
                                            value="Send" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
