import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Team_1 from '../assets/images/team1.png'
import Http from "../Http_Api";
export default function Employee() {
    const [data_employee_title, setData_employee_title] = useState([]);
    const [data_all_employee, setData_all_employee] = useState([]);
    const [data_employee_by_catagory, setData_employee_by_catagory] = useState([]);
    // const [user_type, setUser_type] = useState('worker');
    // const [user_catagory, setUser_catagory] = useState('');
    const windoSCrollCount = useRef(0)
    useEffect(() => {
        if (windoSCrollCount.current === 0) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            windoSCrollCount.current = 1
        }
        let scrolling = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
        get_employee_title();
        get_all_employee();
        // get_employee_by_catagorry();
    }, []);
    let user_catagory = useRef('worker');

    const get_employee_title = async () => {
        await Http.get('/article/employee_page/title').then((res, req) => {
            let value = []
            Object.keys(res.data).map((key) => {
                value.push(res.data[key]);
            })
            setData_employee_title(value)
        })
    }
    const get_all_employee = async () => {
        if (user_catagory.current == 'worker') {
            await Http.get('/users/worker').then((res, req) => {
                let value = []
                Object.keys(res.data).map((key) => {
                    value.push(res.data[key]);
                })
                setData_all_employee(value)
            })
        }
        else {
            await Http.get('/users/worker/' + user_catagory.current).then((res, req) => {
                let value = []
                Object.keys(res.data).map((key) => {
                    value.push(res.data[key]);
                })
                setData_all_employee(value)
            })
        }
    }

    // const get_employee_by_catagorry = () => {

    //     if (user_catagory == 'worker') {
    //         user_catagory = ''
    //     }
    //     Http.get('/users/' + user_type + "/" + user_catagory).then((res, req) => {
    //         // let value = []
    //         // Object.keys(res.data).map((key) => {
    //         //     value.push(res.data[key]);
    //         // })
    //         setData_employee_by_catagory(res.data)
    //     })
    // }

    const Team_vissible = (e, name) => {
        let element = e.target.closest('.tablinks')
        let tablinks = document.getElementsByClassName('tablinks')
        for (let index = 0; index < tablinks.length; index++) {
            const element = tablinks[index];
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            }
        }
        if (!element.classList.contains('active')) {
            element.classList.add('active');
        }


        let employee = document.getElementById(name);
        let employee_all = document.getElementsByClassName('team_member_by_catagory');
        for (let index = 0; index < employee_all.length; index++) {
            const element = employee_all[index];
            if (element.classList.contains('d-block')) {
                element.classList.add('d-none')
                element.classList.remove('d-block')
            }
        }
        // setUser_catagory(name);
        if (employee.classList.contains('d-none')) {
            employee.classList.add('d-block')
            employee.classList.remove('d-none')
        }
        user_catagory.current = name
        get_all_employee();
        // get_employee_by_catagorry();
    }


    const Catagory_data_list = ['worker', 'advisoryBorde', 'official', 'teacher', 'employee'];
    // data.map((d)=>{
    //     console.log(d)
    // })
    return (
        <>
            <section id="page-banner">
                <div className="banner-inner"

                    style={{ backgroundImage: `url(https://source.unsplash.com/random/400x400/?news)` }}>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="banner-contents">
                                    <h1 className='d-block'>{data_employee_title.length > 0 ? data_employee_title[0].title : "get_employee_title()"}</h1>
                                    <nav className="breadcrumbs " style={{ opacity: "1" }}>
                                        <Link to={'/'} className="breadcrumbs__item"><i className="text-black fa-solid fa-house"></i> Home</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Sho</Link>
                                        <Link to={'/'} className="breadcrumbs__item">Cart</Link>
                                        <Link to={'/'} className="breadcrumbs__item is-active">{data_employee_title.length > 0 ? data_employee_title[0].title : "get_employee_title()"}</Link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='All_Employee'>
                <div className="container">
                    <div className="" style={{ margin: '5% 0' }}>
                        <div className="section-title">
                            <h2>{data_employee_title.length > 0 ? data_employee_title[0].title : "get_employee_title()"}</h2>
                            <p>{data_employee_title.length > 0 ? data_employee_title[0].short_description : "get_employee_title()"}</p>
                            <div className="tab">
                                <span className="tablinks btn btn-outline-success mx-2 active"
                                    onClick={(e) => { Team_vissible(e, 'worker') }}>All</span>
                                <span className="tablinks btn btn-outline-success mx-2 "
                                    onClick={(e) => { Team_vissible(e, 'advisoryBorde') }}>উপদেষ্টামন্ডলী</span>
                                <span className="tablinks btn btn-outline-success mx-2"
                                    onClick={(e) => { Team_vissible(e, 'official') }}>কর্মকর্তা</span>
                                <span className="tablinks btn btn-outline-success mx-2"
                                    onClick={(e) => { Team_vissible(e, 'teacher') }}>শিক্ষক</span>
                                <span className="tablinks btn btn-outline-success mx-2"
                                    onClick={(e) => { Team_vissible(e, 'employee') }}>কর্মচারী</span>
                            </div >
                        </div >

                        {Catagory_data_list.map((data) => (
                            <div id={data} className={"team_member_by_catagory " + (data == 'worker' ? 'd-block' : 'd-none')}>
                                <div className="row ">
                                    {data_all_employee.length > 0 ? data_all_employee.map((data) => (
                                        <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                            <div className="single-team">
                                                <div className="team-image">
                                                    <img src={data.profile_image_url}
                                                        alt="" />
                                                </div>
                                                <div className="team-member-details">
                                                    <h4>{data.name}</h4>
                                                    <p>{data.profession}</p>
                                                    <Link to={'/Manage_profile/' + data.id}>সম্পূর্ণ প্রোফাইল</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                    {/* <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        ))}
                        {/* <div id='advisoryBorde' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='official' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id='teacher' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id='employee' className="team_member_by_catagory d-none">
                            <div className="row ">
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-10 mx-auto col-md-6 col-lg-4 my-4">
                                    <div className="single-team">
                                        <div className="team-image">
                                            <img src={Team_1}
                                                alt="" />
                                        </div>
                                        <div className="team-member-details">
                                            <h4>হাফেজ মাওলানা মুফতি শাহেদ রহমানী দা.বা.</h4>
                                            <p>প্রধান উপদেষ্টা</p>
                                            <Link to={'/Manage_profile'}>সম্পূর্ণ প্রোফাইল</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
